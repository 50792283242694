import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // Set variables
  canonical: HTMLLinkElement = document.querySelector("link[rel='canonical']");

  constructor(private metaService: Meta, private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    // Set values for page
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      if (data && data.title) {
        // Set the page title
        this.titleService.setTitle(data.title);

        // Set the meta tags
        this.metaService.updateTag({ name: 'description', content: data.description });
        this.metaService.updateTag({ name: 'keywords', content: data.keywords });
        this.metaService.updateTag({ property: 'og:url', content: data.siteUrl });
        this.metaService.updateTag({ property: 'og:title', content: data.ogTitle });
        this.metaService.updateTag({ property: 'og:description', content: data.ogDescription });
        this.metaService.updateTag({ name: 'twitter:site', content: data.siteUrl });
        this.metaService.updateTag({ name: 'twitter:title', content: data.twitterTitle });
        this.metaService.updateTag({ name: 'twitter:description', content: data.twitterDescription });

        // Set canonical url
        this.canonical.setAttribute('href', data.siteUrl);

        // Check for non index
        if (data.isNoIndexed) {
          this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
        }
      }
    });
  }
}
