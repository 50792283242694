// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://beta.elektron.ai:4000/api',
  // apiBaseUrl: 'http://localhost:4000/api',
  websiteUrl: 'https://beta.elektron.ai',
  manageUrl: 'https://beta.elektron.ai/manage',
  webToken: 'zp8p5uRk6irgHySQUZhzdSn1xQm44O',
  webUser: 'Website',
  version: '1.0.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
